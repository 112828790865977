import React from "react";

import { UnderConstructionMessage } from "./BlogElements";

const Blog = () => {
  return (
    <>
      <UnderConstructionMessage>
        Ova stranica je trenutno u izradi
      </UnderConstructionMessage>
    </>
  );
};

export default Blog;
