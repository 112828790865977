import styled from "styled-components";

export const IndividualniKursContainer = styled.div`
  height: auto;
  text-align: left;
  padding: 100px;

  @media screen and (max-width: 1366px) {
    padding: 0;
    max-width: 1293px;
  }
`;

export const ImgAtTop = styled.img`
  width: 135px;
  height: 135px;
`;

export const FormLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 26px;

  @media screen and (max-width: 375px) {
    flex-direction: column;
    gap: 25px;
  }

  @media screen and (max-width: 414px) {
    flex-direction: column;
    gap: 25px;
  }

  @media screen and (max-width: 430px) {
    flex-direction: column;
    gap: 25px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 25px;
  }

  @media screen and (max-width: 820px) {
    flex-direction: column;
    gap: 25px;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    gap: 55px;
  }
`;
export const UpperContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: row;
  flex: 1;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 2px 2px 15px rgb(17, 29, 30);
  border-left: 5px solid rgb(17, 29, 30);
  width: 100%;
  justify-items: center;

  @media (max-width: 375px) {
    width: 99.5%; /* Take full width of the parent */
    padding: 15px; /* Adjust padding to suit smaller screen */
    border-radius: 10px; /* Adjust border radius for smaller screen */
    box-shadow: 1px 1px 10px rgb(26, 26, 26); /* Adjust shadow if needed */
  }

  @media screen and (max-width: 414px) {
    width: 99.5%;
  }

  @media screen and (max-width: 430px) {
    width: 99.5% !important;
  }

  @media screen and (max-width: 412px) {
    width: 99.5%;
  }

  @media screen and (max-width: 540px) {
    width: 99.5%;
  }
`;

export const UpperLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const UpperRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`;

export const NameOfProduct = styled.h2`
  margin-top: 55px;
  font-size: 40px;
  text-align: start;
  margin-bottom: 24px;

  @media screen and (max-width: 375px) {
    font-size: 25px;
    margin-top: 100px;
  }

  @media screen and (max-width: 414px) {
    font-size: 25px;
    margin-top: 110px;
  }



  @media screen and (max-width: 430px) {
    font-size: 25px;

    margin-top: 110px;
  }

  @media screen and (max-width: 540px) {
    font-size: 25px;

    margin-top: 110px;
`;

export const DurationShort = styled.p`
  margin-bottom: 10px;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
`;

export const Price = styled.h5`
  font-weight: bold;
  font-size: 30px;
`;

export const PDViDostava = styled.p`
  font-size: 0.9em;
  color: rgba(153, 153, 153, 0.5);
  margin-top: 5px;
`;

export const NoteBatteries = styled.h2`
  margin-bottom: 10px;
  font-size: 2.308rem;

  @media screen and (max-width: 375px) {
    word-spacing: 4px;
    line-height: 19px;
    letter-spacing: 0px;
  }

  @media screen and (max-width: 414px) {
    word-spacing: 4px;
    line-height: 19px;
    letter-spacing: 0px;
  }

  @media screen and (max-width: 390px) {
    word-spacing: 4px;
    line-height: 19px;
    letter-spacing: 0px;
  }

  @media screen and (max-width: 430px) {
    word-spacing: 4px;
    line-height: 19px;
    letter-spacing: 0px;
  }

  @media screen and (max-width: 360px) {
    word-spacing: 4px;
    line-height: 19px;
    letter-spacing: 0px;
  }

  @media screen and (max-width: 412px) {
    word-spacing: 4px;
    line-height: 19px;
    letter-spacing: 0px;
  }

  @media screen and (max-width: 344px) {
    word-spacing: 4px;
    line-height: 19px;
    letter-spacing: 0px;
  }

  @media screen and (max-width: 412px) {
    word-spacing: 4px;
    line-height: 19px;
    letter-spacing: 0px;
  }
`;

export const KolicinaContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const Minus = styled.span`
  border: 1px solid rgba(110, 110, 110, 0.5);
  height: 34px;
  width: 34px;
  border-radius: 50%;
  opacity: 0.5;
  cursor: pointer;
  display: flex;
  padding-bottom: 3.5px;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  background-color: transparent;
  }
`;

export const Kolicina = styled.div`
  font-size: 23px;
  color: #6e6e6e;
  background-color: transparent;
  width: 1px;
  min-width: 50px;
  padding: 0;
  border: 0;
  box-shadow: none;
  text-align: center;
`;

export const Plus = styled.button`
  border: 1px solid rgba(110, 110, 110, 0.5);
  height: 34px;
  width: 34px;
  border-radius: 50%;
  opacity: 0.5;
  cursor: pointer;
  display: flex;
  padding-bottom: 3.5px;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  background-color: transparent;
`;

export const ImageGalleryContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  flex: 1;
`;
export const ThumbnailColumn = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 400px;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
`;

export const Thumbnail = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-bottom: 10px;
  cursor: pointer;
  border: 2px solid ${(props) => (props.selected ? "#000" : "transparent")};
  transition: all 0.3s ease;
`;

export const MainImage = styled.img`
  width: 100%;
  max-width: 400px;
  height: auto;
  object-fit: contain;
`;

export const FormContainer = styled.form`
  padding: 40px;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 15px;
  border-left: 5px solid #ffca00;
  box-shadow: 2px 2px 15px rgb(26, 26, 26);
  margin-left 20px;
  width: 100%;

   @media screen and (max-width: 375px) {
       width: 99.5%;
    justify-content: center
  }
      @media screen and (max-width: 414px) {
    width: 99.5%;
  }

   @media screen and (max-width: 430px) {
    width: 99.5% !important;
  }
`;

export const TitleForm = styled.h3`
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
`;

export const DuratioFormDate = styled.p`
  font-weight: bold;
  text-align: center;
`;

export const PForm = styled.p`
  margin-bottom: 10px;
  font-weight: bold;
  text-align: center;
`;

export const Input = styled.input`
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
`;

export const InputNapomena = styled.textarea`
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  height: 100%;
  box-sizing: border-box;

  ::placeholder {
    color: #999;
    font-size: 14px;
  }
`;

export const DropDownContainer = styled.div`
  position: relative;
  padding-top: 100px;
  width: 100%;
`;

//// NOVI KOJI RADE

export const DropdownContainer = styled.div`
  width: 100%;
  margin-bottom: 30px; /* Adjust as needed for spacing between dropdowns */
  position: relative;
  border-radius: 7px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.7);
  padding: 20px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 24px;
  margin-bottom: 10px;
  margin-left: 10px;
  font-weight: bold;
`;

export const Arrow = styled.div`
  border: solid black;
  border-width: 0 4px 4px 0;
  display: inline-block;
  margin-right: 15px;
  width: 20px;
  height: 20px;
  padding: 3px;
  transform: ${(props) => (props.isOpen ? "rotate(-135deg)" : "rotate(45deg)")};
  transition: all 0.7s ease-in-out;
`;

export const DropdownContent = styled.div`
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  max-height: ${(props) => (props.isOpen ? "1000px" : "0")};
  overflow: hidden;
  transition: opacity 0.7s ease-in-out, max-height 0.7s ease-in-out;
  margin-left: 10px;
  font-size: 20px;
`;
